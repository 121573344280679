






import { Component, Vue, Provide } from 'vue-property-decorator';
// @ts-ignore
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
// @ts-ignore
import en_US from 'ant-design-vue/lib/locale-provider/en_US';
import { setTenant, getCurrentLang } from '@/utils';
import { rootStore } from '@/stores/rootStore';
import { Tenant } from '@/models/userModels';
import tenantList from '@/tenant.config';

@Component
export default class App extends Vue {
  @Provide()
  public rootStore = rootStore;

  public get locale() {
    return getCurrentLang() === 'zh' ? zh_CN : en_US;
  }

  public get env(): string {
    return process.env.VUE_APP_ENV!;
  }

  public created() {
    const tenant = window.location.host;
    const obj = tenantList.filter(
      (item: any) => item.host[this.env] === tenant
    );
    if (obj.length) {
      document.title = obj[0].tenantName;
      setTenant(obj[0]);
    }
  }
}
