import Vue from 'vue';
import App from './App.vue';
import router from './router';
import { i18n } from '@/i18n';
import './style/index.less';
import axios from '@/utils/fetch';
import * as Antd from 'adapt-antd';
import { fileToken } from 'adapt-antd';
import { OtherApi } from '@/apis/otherApi';
import InputSelect from '@/components/InputSelect.vue';
import CardLayout from '@/components/layout/CardLayout.vue';
import { FieldType, JsonViewMaker } from 'vue-json-view-maker';
import ContentLayout from '@/components/layout/ContentLayout.vue';
import {
  Button,
  Col,
  ConfigProvider,
  Dropdown,
  Form,
  FormModel,
  Icon,
  Input,
  List,
  Menu,
  Modal,
  message,
  Row,
  Select,
  Upload,
} from 'ant-design-vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import config from '../package.json';

Vue.config.productionTip = false;

Vue.component('mh-input-select', InputSelect);
Vue.component('card-layout', CardLayout);
Vue.component('content-layout', ContentLayout);

Vue.use(Button);
Vue.use(Col);
Vue.use(ConfigProvider);
Vue.use(Dropdown);
Vue.use(Form);
Vue.use(FormModel);
Vue.use(Icon);
Vue.use(Input);
Vue.use(List);
Vue.use(Menu);
Vue.use(Modal);
Vue.use(Row);
Vue.use(Select);
Vue.use(Upload);
Vue.use(JsonViewMaker, { http: axios, fileDomain: 'https://file.enhertz.com' });
JsonViewMaker.use(Antd);
JsonViewMaker.setHttpMethod('get');

fileToken.setGetToken(() => OtherApi.qiniuToken());

Vue.prototype.$message = message;
Vue.prototype.$confirm = Modal.confirm;


new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
