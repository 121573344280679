

















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ContentLayout extends Vue {
  @Prop()
  public paths!: any[];
  @Prop({
    default: false,
  })
  public showTitle!: boolean;
  public get title() {
    const { paths, showTitle } = this;
    if (!showTitle || !paths || paths.length < 2) {
      return '';
    }
    return paths[paths.length - 1].title;
  }
  public goBack() {
    this.$router.back();
  }
}
