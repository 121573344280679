
































import { Component, Vue } from "vue-property-decorator";
import { setCurrentLang, getCurrentLang, getTenant } from "@/utils";
import { rootStore } from "@/stores/rootStore";

@Component
export default class Header extends Vue {
  public tenant: any = getTenant();
  public current: any[] = ["index"];
  public menu: any[] = [
    {
      key: "index",
      value: "index",
    },
    {
      key: "teacherAccMgt",
      value: "accountMgt",
    },
    {
      key: "optLog",
      value: "operationLog",
    },
  ];

  public created(): void {
    rootStore.getUserInfo();
    // rootStore.getFormOpt();
    this.current = [this.$route.name as string];
  }

  public get auth() {
    return rootStore.menuAuth;
  }

  public get userInfo() {
    return rootStore.user;
  }

  public onClick({ key }: any): void {
    switch (key) {
      case "userCenter":
        this.$router.push({
          name: "detail",
          params: { id: this.userInfo.userId },
        });
        break;
      case "lang":
        setCurrentLang(getCurrentLang() === "zh" ? "en" : "zh");
        break;
      case "logout":
        window.location.href = "/api/logout";
        break;
    }
  }

  public changeRouter(e: any): void {
    this.current = [e.key];
    this.$router.push({ name: e.key }).catch(() => {});
  }
}
