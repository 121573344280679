




























import { Component, Vue } from 'vue-property-decorator';
import { FormApi } from '../../apis/formApi';
import { getFormId } from '@/configs/formMap';
import { CreateElement } from 'vue';

@Component
export default class FormManage extends Vue {
  public search = '';
  public getData = FormApi.getFormListPage;
  public deleteApi = (formId: string, id: string) => FormApi.deleteForm(id);

  public get formId() {
    return getFormId('formManage');
  }
  public get filter() {
    if (this.search) {
      return [{ name: 'name', method: 'regex', value: this.search }];
    }
    return [];
  }
  public actionRender(h: CreateElement, actions: any) {
    return {
      title: this.$t('common.action'),
      key: 'action',
      customRender: (record: any) => {
        return [
          h(
            'router-link',
            {
              props: { to: { name: 'adminDesign', params: { id: record.id } } },
            },
            '编辑'
          ),
          h('a-divider', { props: { type: 'vertical' } }),
          h('a', { on: { click: () => actions.delete(record.id) } }, '删除'),
        ];
      },
    };
  }
  public onSearch(text: string) {
    this.search = text;
  }
}
