import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { rootStore } from '@/stores/rootStore';
import FormManage from '@/views/setting/FormManage.vue';
import Design from '@/views/design/Design.vue';
import Login from '@/views/login/Login.vue';
import Main from '@/views/Main.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/',
    component: Main,
    redirect: { name: 'index' },
    children: [
      {
        path: '/index',
        name: 'index',
        component: () =>
          import(/* webpackChunkName: "home" */ '@/views/home/Home.vue'),
      },
      {
        path: '/account',
        name: 'teacherAccMgt',
        component: () =>
          import(
            /* webpackChunkName: "home" */ '@/views/account/TeacherAccMgt.vue'
          ),
      },
      {
        path: '/optLog',
        name: 'optLog',
        component: () =>
          import(/* webpackChunkName: "home" */ '@/views/log/Log.vue'),
      },
      {
        path: '/personnel',
        name: 'detail',
        component: () =>
          import(
            /* webpackChunkName: "home" */ '@/views/personnel/PersonnelDetail.vue'
          ),
      },
      {
        path: '/bulletin/:id',
        name: 'bulletin',
        component: () =>
          import(
            /* webpackChunkName: "home" */ '@/views/home/BulletinDetail.vue'
          ),
      },
      {
        path: '/formManage',
        name: 'formManage',
        component: FormManage,
      },
      {
        path: '/design/:id',
        name: 'adminDesign',
        component: Design,
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/403',
    name: '403',
    component: () =>
      import(/* webpackChunkName: "home" */ '@/views/error/403.vue'),
  },
  {
    path: '/404',
    name: 'notFound',
    component: () =>
      import(/* webpackChunkName: "home" */ '@/views/error/404.vue'),
  },
  {
    path: '*',
    redirect: '/404',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const authFlag = () => {
  const cookies = document.cookie;
  const list = cookies.split(';');
  const cookiesList = list.map((item) => item.split('='));
  for (const item of cookiesList) {
    if (item[0] === 'login') {
      return true;
    }
  }
  return false;
};

const whiteList: any = [
  'login',
  'index',
  '404',
  '403',
  'loading',
  'detail',
  'bulletin',
  'adminDesign',
  'formManage',
];

router.beforeEach(async (to, from, next) => {
  if (!rootStore.hasLoadMenu && to.name !== 'login') {
    await rootStore.getMenuAuth();
  }
  if (whiteList.includes(to.name) || rootStore.menuAuth.includes(to.name)) {
    next();
  } else {
    next({ name: '403' });
  }
});

export default router;
