import fetch from '../utils/fetch';

export class PortalController {
  /**
   * 获取权限
   */

  public static portalAuthorities() {
    return fetch.get<any>('/portal/authorities');
  }
}

export class UserController {
  /**
   * 获取用户信息
   */

  public static getUserInfo() {
    return fetch.get<any>('/api/user/info');
  }
  /**
   * 禁用
   */

  public static getUserUpdateDisable(ssoStatus: any, userId: any) {
    return fetch.put<any>(
      '/api/user/updateDisable',
      {},
      { params: { ssoStatus, userId } }
    );
  }
  /**
   * 获取权限
   */

  public static getUserModuleAuthorities() {
    return fetch.get<any>('/api/user/module/authorities');
  }
  /**
   * 设置管理员
   */

  public static setAdmin(ids: any) {
    return fetch.put<any>('/api/user/setAdmin', { ids });
  }
  public static requestName(request: any) {
    return fetch.get<any>('api/user/info/hr', { params: request });
  }
  public static addName(request: any) {
    return fetch.put<any>('api/user/info', request);
  }

  public static getUserGroupsByAdmin() {
    return fetch.get<any>('/api/user/getUsersGroupByAdmin');
  }
}

export class UserOptLogController {
  /**
   * 添加操作记录
   */

  public static addUserOptLog(request: any) {
    return fetch.post<any>('/api/userOptLog/add', request);
  }
}

export class AnnouncementController {
  /**
   * 添加操作记录
   */

  public static getPage(request: any) {
    return fetch.get<any>('/api/announcement/page', { params: request });
  }

  public static getUnReadNum() {
    return fetch.get<any>('/api/announcement/unReadNum');
  }

  public static updateRead(id: string) {
    return fetch.put<any>('/api/announcement/updateRead', undefined, {
      params: { announcementId: id },
    });
  }
}

export class HrController {
  public static hrSync() {
    return fetch.post<any>('/api/hr/sync');
  }
}

export class FormController {
  public static getList(authId: string, matches: any[] = []) {
    return fetch
      .get('/api/db/list', {
        params: { authId, matches: JSON.stringify(matches) },
      })
      .then((res: any) => res.data || []);
  }
}
