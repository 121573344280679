export default [
  {
    key: 'enhertz',
    tenantId: '609cc03a66660000f6005fd8',
    tenantName: 'IntSchool智慧校园信息管理系统',
    tenantEnName: 'IntSchool智慧校园信息管理系统',
    loginType: ['INTOA'],
    config: {
      production: [],
      default: [],
    },
    host: {
      production: 'int.enhertz.com',
      dev:'localhost:8080',
    },
  },
];
