



















































































import { Component, Vue } from 'vue-property-decorator';
import {
  JsonViewMaker,
  baseComponents,
  advanceComponents,
} from 'vue-json-view-maker';
import { FormApi } from '@/apis/formApi';
import { message } from 'ant-design-vue';
import { getCurrentLang, setCurrentLang } from '@/utils';
import inputSelect from './inputSelect.json';
import checkboxGroup from './checkboxGroup.json';

const textareaCompoent: any = baseComponents.find(
  (item) => item.name === 'textarea'
);
const imguploadCompoent: any = advanceComponents.find(
  (item) => item.name === 'imgupload'
);

const newBaseComponents = [
  ...baseComponents.filter(
    (item) =>
      item.name !== 'textarea' &&
      item.name !== 'imgupload' &&
      item.name !== 'checkboxgroup'
  ),
  {
    name: 'rangePicker',
    title: '时间范围',
    type: {
      type: 'list',
      items: {
        type: 'date',
        format: '{{children.format}}',
      },
    },
    component: {
      tag: 'mh-form-item',
      children: {
        tag: 'mh-range-picker',
      },
      _lang: [
        {
          _path: 'label',
          zh: '时间范围',
        },
      ],
    },
    property: [
      {
        tag: 'mh-form-item',
        name: 'label',
        label: '标题',
        children: {
          tag: 'a-input',
        },
      },
      {
        tag: 'mh-form-item',
        name: 'children.format',
        label: '格式',
        children: {
          tag: 'a-select',
          options: [
            {
              label: '年-月-日',
              value: 'YYYY-MM-DD',
            },
            {
              label: '年-月-日 时-分',
              value: 'YYYY-MM-DD HH:mm',
            },
          ],
        },
      },
      {
        tag: 'mh-form-item',
        name: 'children.showTime',
        label: '显示时间',
        options: {
          valuePropName: 'checked',
        },
        children: {
          tag: 'a-checkbox',
          children: '必填',
        },
      },
      {
        tag: 'mh-form-item',
        name: 'required',
        label: '校验',
        options: {
          valuePropName: 'checked',
        },
        children: {
          tag: 'a-checkbox',
          children: '必填',
        },
      },
    ],
  },
  {
    ...textareaCompoent,
    property: [
      ...textareaCompoent.property,
      {
        tag: 'mh-form-item',
        name: 'children.autoSize.minRows',
        label: '最小内容高度',
        children: {
          tag: 'a-input-number',
        },
      },
      {
        tag: 'mh-form-item',
        name: 'children.autoSize.maxRows',
        label: '最大内容高度',
        children: {
          tag: 'a-input-number',
        },
      },
    ],
  },
  checkboxGroup,
];

const newAdvanceComponent = [
  ...advanceComponents.filter(
    (item) => item.name !== 'textarea' && item.name !== 'imgupload'
  ),
  {
    ...imguploadCompoent,
    property: [
      ...imguploadCompoent.property,
      {
        tag: 'mh-form-item',
        name: 'showImage',
        label: '显示图片',
        options: {
          valuePropName: 'checked',
        },
        children: {
          tag: 'a-checkbox',
        },
      },
    ],
  },
];

const addExtraProperty = (n: any) => {
  return {
    ...n,
    property: [
      {
        tag: 'mh-form-item',
        name: 'name',
        label: '字段名',
        children: {
          tag: 'a-input',
        },
      },
      ...n.property,
      {
        tag: 'mh-form-item',
        name: 'extra',
        label: '额外信息',
        children: {
          tag: 'a-input',
        },
      },
    ],
  };
};
const propertyList: any[] = [
  {
    tag: 'mh-form-item',
    name: '_summary',
    label: '摘要字段',
    children: {
      tag: 'mh-select',
      mode: 'multiple',
      placeholder: '默认全部',
      options: '{{formItemList}}',
      optionLabel: 'label',
      optionValue: 'name',
    },
  },
];

@Component
export default class Design extends Vue {
  public propertyMode = '1';
  public baseComponents = newBaseComponents.map(addExtraProperty);
  public advanceComponents = [...newAdvanceComponent, inputSelect].map(
    addExtraProperty
  );
  public showInput = false;
  public inputName = '';
  public inputEnName = '';
  public inputCollection = '';
  public show = false;
  public json = '';
  public propertyList = propertyList;
  public jsonEditShow = false;
  public jsonEdit = '';

  public get lang() {
    return getCurrentLang();
  }

  public get id() {
    return this.$route.params.id;
  }

  public created() {
    const id = this.id;
    if (id !== '-1') {
      FormApi.getForm(id).then((data) => {
        if (data) {
          this.inputName = data.name || '';
          this.inputEnName = data.enName || '';
          JsonViewMaker.setJson(JSON.stringify(data));
        }
      });
    }
  }

  public langChange(e: any) {
    setCurrentLang(e.target.value);
  }

  public saveForm() {
    let obj: any = {};
    try {
      obj = JSON.parse(JsonViewMaker.getJson());
    } catch (e) {
      message.error('存在重复字段名');
      return;
    }
    obj.name = this.inputName;
    obj.enName = this.inputEnName;
    const json = JSON.stringify(obj);
    if (this.id === '-1') {
      FormApi.saveForm(this.inputName, this.inputCollection, json).then(
        (data) => {
          if (data) {
            this.$router.replace({ name: 'adminDesign', params: { id: data } });
            this.showInput = false;
            message.success('保存成功');
            this.$router.back();
          }
        }
      );
    } else {
      FormApi.editForm(this.id, this.inputName, json).then(() => {
        this.showInput = false;
        message.success('保存成功');
        this.$router.back();
      });
    }
  }

  public preview() {
    this.show = true;
    this.json = JsonViewMaker.getJson();
  }

  public reset() {
    JsonViewMaker.reset();
  }

  public showJsonEdit() {
    this.jsonEditShow = true;
    this.jsonEdit = JsonViewMaker.getJson();
  }

  public saveJsonEdit() {
    JsonViewMaker.setJson(this.jsonEdit);
    this.jsonEditShow = false;
  }
}
