const map: any = {
  '5ee1da1e1b370000df000794': {
    personnel: {
      formId: '5f029962f8657d26d02aee95',
    },
  },
  formManage: {
    formId: '5f057dc8c919b11a30867bc6',
  },
  appList: {
    formId: '5f2a23b5adbe1d0001a39df9',
  },
  accountMgt: {
    formId: '5f28c99eadbe1d0001a39de2',
  },
  outsider: {
    formId: '600e775324aa9a00011c9d27',
  }
};

export function getFormMap(key: string) {
  if (map[key]) {
    return map[key];
  }
  // const tenant: string = getTenant() || '';
  // if (tenant && map[tenant]) {
  //   return map[tenant][key];
  // }
}

export function getFormId(key: string) {
  if (map[key]) {
    return map[key].formId;
  }
  const tenant: string = '5ee1da1e1b370000df000794';
  if (tenant && map[tenant] && map[tenant][key]) {
    return map[tenant][key].formId;
  }
}
