import Vue from 'vue';
import { OtherApi } from '@/apis/otherApi';
import { getFormId } from '@/configs/formMap';
import { VueRouter } from 'vue-router/types/router';
import { UserController } from '@/services/request.service';
import { setUser, getTenant } from '@/utils';
import { Tenant } from '@/models/userModels';
import tenantList from '@/tenant.config';

export class RootStore {
  public tenantList: any[] = tenantList;
  public menuList: any[] = [];
  // 表单操作权限
  public formOpt: any = {};
  // 加载菜单的 Promise
  public loadMenu: Promise<void> | null = null;
  // 菜单是否加载完毕
  public hasLoadMenu = false;
  // 用户信息
  public user: any = {};

  public menuAuth: any = [];

  public router: VueRouter | null = null;

  // 当前租户
  public get currentTenant() {
    const tenant = getTenant().tenantId;
    return (
      this.tenantList.find((t: any) => t.tenantId === tenant) || ({} as Tenant)
    );
  }

  // 所有菜单的key
  public get menuNames() {
    return this.menuList.map((m) => m.name);
  }

  // 根据 key 获取表单权限
  public getFormOptByKey(key: string) {
    return this.transOpt2Actions(this.formOpt[getFormId(key)] || []);
  }

  // 根据 formId 获取表单权限
  public getFormOptByFormId(formId: string) {
    return this.transOpt2Actions(this.formOpt[formId] || []);
  }

  // 根据 key 和 操作 判断是否有权限
  public hasAuth(key: string, opt: string) {
    return this.getFormOptByKey(key).includes(opt);
  }

  public getMenuList(client: string) {
    this.loadMenu = OtherApi.getMenuList(client)
      .then((data) => {
        if (data) {
          this.menuList = data;
        }
      })
      .finally(() => {
        this.hasLoadMenu = true;
      });
  }

  public getFormOpt() {
    OtherApi.getFormOpt().then((data) => {
      this.formOpt = data || {};
    });
  }

  public getUserInfo() {
    UserController.getUserInfo().then((res) => {
      this.user = res.data || {};
      setUser(res.data);
    });
  }

  public setUserInfo(info: any) {
    this.user = info;
  }

  public getMenuAuth() {
    return UserController.getUserModuleAuthorities()
      .then((res) => {
        this.menuAuth = res.data;
      })
      .finally(() => {
        this.hasLoadMenu = true;
      });
  }

  // 后端数据转为前端 actions
  private transOpt2Actions(opts: string[]) {
    const result = [];
    if (opts.includes('query')) {
      result.push('viewLine');
      result.push('filter');
    }
    if (opts.includes('update')) {
      result.push('edit');
    }
    if (opts.includes('save')) {
      result.push('add');
    }
    if (opts.includes('delete')) {
      result.push('delete');
    }
    return result;
  }
}

export const rootStore = Vue.observable(new RootStore());
