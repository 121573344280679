










import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class InputSelect extends Vue {
  @Model('change')
  public value!: string;
  @Prop({
    default: () => [],
  })
  public list!: string[];
  @Prop({
    default: false,
  })
  public defaultFirst!: boolean;

  public get inputValue() {
    const { value, selectValue } = this;
    if (!selectValue) {
      return value || '';
    }
    return value.substring(0, value.length - selectValue.length);
  }

  public get selectValue() {
    const { value, list } = this;
    if (!value) {
      return '';
    }
    const rValue = value.split('').reverse().join('');
    return list.find((item) => {
      if (value.length < item.length) {
        return false;
      }
      item = item.split('').reverse().join('');
      return rValue.substring(0, item.length) === item;
    }) || '';
  }

  public created() {
    const { defaultFirst, inputValue, list } = this;
    if (defaultFirst && list.length && !this.selectValue) {
      this.$emit('change', inputValue + list[0]);
    }
  }

  public inputChange(e: any) {
    this.$emit('change', e.target.value + this.selectValue);
  }

  public selectChange(v: string) {
    this.$emit('change', this.inputValue + v);
  }
}
