export const zh = {
  login: {
    huaer: '昆山狄邦华曜学校',
    title: '用户登录',
    email: '请输入邮箱',
    password: '请输入邮箱密码',
    remember: '7天内自动登录',
    forget: '忘记密码',
    login: '登录',
    emailError: '请输入正确的邮箱账号',
    passwordError: '密码必填，且长度为1-50',
    loginError: '账号或者密码错误',
    disable: '账号被禁用，请联系管理员',
    notAssociateApp: '账号未关联应用，请联系管理员',
    notSyncLdap: '账号暂未添加至域控，请联系管理员',
    emailLogin: '使用intunion账号登录',
    domainLogin: '使用域账号登录',
  },
  lang: '切换语言',
  logout: '退出登录',
  userCenter: '个人中心',
  more: '更多',
  schoolNews: '学校新闻',
  notice: '通知公告',
  myPlatform: '我的平台',
  teacherPlatform: '教师端',
  adminPlatform: '管理端',
  approve: '审批',
  meetingRoom: '会议室',
  home: '首页',
  attendance: '学生考勤',
  daybook: '日记簿',
  message: '消息',
  enquiries: '新咨询',
  applicants: '新申请',
  moments: '成长记录',
  downloadExcel: '导出Excel',
  unread: '未读',
  unreadMsgCounter: '{num} 条未读通知',
  attachments: '附件（{num}）',
  return: '返回',
  name: '中文名',
  enName: '英文名',
  email: '邮箱',
  employeeType: '员工类型',
  groupManagement:'集团人员管理',
  emailInvaild: '请输入合法邮箱',
  nameInvaild: '必填，且长度在1-50之间',
  pinyinNameInvaild: '必填，且长度在1-50之间，且只能输入字母',
  pleaseSelect: '请选择',
  supplier:'供应商',
  else:'其他',
  personnel: {
    title: '人员管理',
    staffDetail: '人员详情',
    cardTitle: '部门人员',
    searchDepartment: '请输入部门名称',
    searchName: '请输入姓名',
    searchNameOr: '请输入姓名、英文名',
    baseInf: '基本信息',
    personnelInf: '人事信息',
    contractList: '合同列表',
    workingExp: '教学/工作经验',
    additionalFile: '附加文件',
    trainingRecord: '培训记录',
    exaluationRecord: '评估记录',
    companyAssets: '公司资产',
    contacts: '通讯录',
    supervisor: '主管/负责人',
    setSupervisor: '设置主管',
    setCharge: '设置负责人',
    nameOrEmail: '姓名/邮箱',
    name: '中文名',
    enName: '英文名',
    code: '工号',
    school: '学校',
    position: '职位',
    role: '角色',
    rightGroups: '权限组',
    email: '邮箱',
    phone: '手机',
    account: '账户信息',
    password: '密码',
    currentPassword: '当前密码',
    newPassword: '新密码',
    confirmNewPassword: '确认新密码',
    updatePassword: '修改密码',
    passwordNotMatch: '两次密码输入不一致',
  },
  fileUploadFailed: '{name}上传失败',
  fileUploadSuccess: '{name}上传成功',
  studentFailedImport: '部分学生导入失败',
  impportResult: '导入成功 {success} 条，导入失败 {fail} 条',
  downloadFailedData: '下载失败数据',
  uploadFile: '上传文件',
  downloadTemplate: '下载导入模版',
  downloadTip: '1.务必下载系统导入模板',
  downloadTip1: '2.按格式要求填写学号和邮箱信息，并上传文件。',
  importEmailTip: '注：只能导入已入学的学生',
  account: {
    relatedApps: '关联应用',
    status: '状态',
    disable: '禁用',
    able: '启用',
    selected: '已选择 {num} 条',
    totalNum: '一共：{num} 条',
    clear: '清除',
    resetPwd: '重置密码',
    addOutSider: '新增集团总部人员',
    adminManage: '设置管理员',
    addAdmin: '新增管理员',
    adminList: '已有管理员',
    updateInfo: '同步更新人员信息',
    hrSyncSuccess: '同步成功，请刷新当前页面',
  },
  nav: {
    index: '首页',
    accountMgt: '账号管理',
    operationLog: '操作日志',
  },
  tip: {
    backToHome: '返回主页',
    tip404: '抱歉，您访问的页面不存在',
    tip403: '抱歉，您暂无权限访问该页面',
    fieldRequired: '{field}字段必填',
    updateSuccess: '修改成功',
    disable: '确认禁用？',
    disableTip: '禁用后，关联应用系统将全部不能登录。',
    able: '确认启用？',
    ableTip: '启用后，关联应用系统将全部可以登录。',
    permissionErr: '暂无系统权限，请联系管理员。',
  },
  common: {
    action: '操作',
    edit: '编辑',
    disable: '禁用',
    enable: '启用',
    delete: '删除',
    add: '添加',
    addSuccess: '添加成功',
    saveSuccess: '保存成功',
    actionSuccess: '操作成功',
    submitSuccess: '提交成功',
    resetPwd: '确认重置密码？',
  },
  log: {
    operator: '操作人',
    target: '操作对象',
    type: '操作类型',
    content: '操作内容',
    time: '操作时间',
  },
  bulletin: '公告通知',
  bulletinDetail: '公告详情',
};
