import axios from '../utils/fetch';
import { BaseApi } from '@/apis/baseApi';

export class FormApi extends BaseApi {
  public static async getForm(id: string) {
    if (!id) {
      return;
    }
    return await axios.get('/api/form', { params: { id } }).then((res: any) => res.data);
  }

  public static getFormListPage(data: any) {
    return axios
      .get('/api/form/page', {
        params: { ...data, matches: JSON.stringify(data.matches || []) },
      })
      .then((res: any) => res.data || []);
  }

  public static getFormList() {
    return axios.get('/api/form/list').then((res: any) => res.data);
  }

  public static deleteForm(id: string) {
    return axios.delete('/api/form', { params: { id } }).then((res: any) => res.data);
  }

  public static saveForm(name: string, collection: string, jsonConfig: string) {
    return axios.post('/api/form', { name, collection, jsonConfig }).then((res: any) => res.data);
  }

  public static editForm(id: string, name: string, jsonConfig: string) {
    return axios.put('/api/form', { id, name, jsonConfig }).then((res: any) => res.data);
  }

  public static getList(authId: string, matches: any[] = [], order?: string, ascFlag?: boolean) {
    return axios
      .get('/api/db/list', {
        params: { authId, matches: JSON.stringify(matches), order, ascFlag },
      })
      .then((res: any) => res.data || []);
  }

  public static getPage(data: any) {
    return axios
      .get('/api/db/page', {
        params: { ...data, matches: data.matches ? JSON.stringify(data.matches) : undefined },
      })
      .then((res: any) => res.data || []);
  }

  public static getData(authId: string, id: string) {
    return axios
      .get('/api/db/list', {
        params: {
          authId,
          matches: JSON.stringify([{ name: '_id', method: 'eq', value: id }]),
        },
      })
      .then((res: any) => res && res.data && res.data[0]);
  }

  public static addData(authId: string, data: any) {
    return axios.post('/api/db', { authId, data }).then((res: any) => res.data);
  }

  public static editData(authId: string, data: any): Promise<void> {
    return axios.put('/api/db', { authId, data }).then((res: any) => res.data);
  }

  public static deleteData(authId: string, id: string) {
    return axios.delete('/api/db', { params: { authId, data: { _id: id } } }).then((res: any) => res.data);
  }

  public static batchAddData(authId: string, data: any[]) {
    const params = data.map(d => ({ authId, data: d }));
    return axios.post('/api/db/batch', params).then(res => res.data);
  }

  public static batchEditData(authId: string, data: any[]) {
    const params = data.map(d => ({ authId, data: d }));
    return axios.put('/api/db/batch', params).then(res => res.data);
  }
}
