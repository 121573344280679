export const en = {
  login: {
    huaer: 'Dipont Huayao Collegiate School Kunshan',
    title: 'User Login',
    email: 'Please enter email',
    password: 'Please enter email password',
    remember: 'Automatic login within 7 days',
    forget: 'Forget password',
    login: 'Login',
    emailError: 'Please enter the correct email account',
    passwordError: 'Password cannot be empty',
    loginError: 'Wrong account or password',
    disable: 'The account is disabled, please contact the administrator.',
    notAssociateApp:
      'The account is not associated with an application, please contact the administrator.',
    notSyncLdap:
      'The account has not been added to the domain controller yet, please contact the administrator.',
    emailLogin: 'Login with intunion account',
    domainLogin: 'Login with domain account',
  },
  lang: 'Switch language',
  logout: 'Logout',
  userCenter: 'User Center',
  more: 'More',
  schoolNews: 'School News',
  notice: 'School Bulletin',
  myPlatform: 'My platform',
  teacherPlatform: ' Teacher Platform',
  adminPlatform: ' Admin Platform',
  approve: 'Approve',
  meetingRoom: 'Meeting Room',
  home: 'Home',
  attendance: 'Attendance',
  daybook: 'Daybook',
  message: 'Message',
  enquiries: 'New Enquiries',
  applicants: 'Applicant',
  moments: 'Moments',
  downloadExcel: 'Download Excel',
  unread: 'Unread',
  unreadMsgCounter: '{num} unread notifications',
  attachments: 'Attachments({num})',
  return: 'Return',
  name: 'Name',
  enName: 'English Name',
  email: 'E-mail',
  employeeType: 'Employee Type',
  groupManagement:'Group Personnel Management',
  emailInvaild: 'Please enter a valid email',
  nameInvaild: 'Chinese name is required and the length is between 1-50.',
  enNameInvaild: 'English name is required, the length is between 1-50, and only letters can be entered.',
  pleaseSelect: 'Please select',
  supplier:'Supplier',
else:'else',
  personnel: {
    title: 'Staff',
    staffDetail: 'Staff Detail',
    cardTitle: 'Department Staff',
    searchDepartment: 'Please enter department name',
    searchName: 'Please enter name',
    searchNameOr: 'Please enter name or english name',
    baseInf: 'Basic Information',
    personnelInf: 'Personnel Information',
    contractList: 'Contract List',
    workingExp: 'Teaching/Working Experience',
    additionalFile: 'Additional File',
    trainingRecord: 'Training Record',
    exaluationRecord: 'Evaluation Record',
    companyAssets: 'Company Assets',
    contacts: 'Contacts',
    supervisor: 'Supervisor/Person In Charge',
    setSupervisor: 'Set Supervisor',
    setCharge: 'Set Person In Charge',
    nameOrEmail: 'Name/Email',
    name: 'Name',
    enName: 'EnName',
    code: 'Code',
    school: 'School',
    position: 'Position',
    role: 'Role',
    rightGroups: 'Right Groups',
    email: 'Email',
    phone: 'Phone',
    account: 'Account Info',
    password: 'Password',
    currentPassword: 'Current Password',
    newPassword: 'New Password',
    confirmNewPassword: 'Confirm New Password',
    updatePassword: 'Update Password',
    passwordNotMatch: "Two inputs don't match",
  },
  fileUploadFailed: '{name} file upload failed.',
  fileUploadSuccess: '{name} file Uploaded successfully',
  studentFailedImport: 'Some students failed to import.',
  impportResult: '{success} successful imports, {fail} failed imports',
  downloadFailedData: 'Download failed data',
  uploadFile: 'Upload File',
  importEmailTip: 'Note: Only enrolled students can be imported',
  downloadTemplate: 'Download import template',
  downloadTip: '1. Be sure to download the system import template',
  downloadTip1:
    '2. Fill in the student ID and email information according to the format requirements, and upload the file.',
  account: {
    relatedApps: 'Related Apps',
    status: 'Status',
    disable: 'Disable',
    able: 'Able',
    selected: 'Selected {num}',
    totalNum: 'Total: {num}',
    clear: 'Clear',
    resetPwd: 'Reset password',
    addOutSider: 'Add Staff',
    adminManage: 'Administrator',
    addAdmin: 'Add Administrator',
    adminList: 'Administrators',
    updateInfo: 'Update information',
    hrSyncSuccess: 'Synchronization is successful, please refresh the current page',
  },
  nav: {
    index: 'Home',
    accountMgt: 'Account Management',
    operationLog: 'Operation Log',
  },
  tip: {
    backToHome: 'Back to home',
    tip404: 'Sorry, the page you visited does not exist.',
    tip403: 'Sorry, you do not have permission to access this page.',
    fieldRequired: 'The {field} field is required.',
    updateSuccess: 'Successfully modified',
    disable: 'Confirm to disable?',
    disableTip:
      'Once disabled, all associated application systems will not be able to log in.',
    able: 'Confirm to enable?',
    ableTip:
      'After enabling, all associated application systems will be able to log in.',
    permissionErr: 'No system permission, please contact the administrator.',
  },
  common: {
    action: 'Action',
    edit: 'Edit',
    disable: 'Disable',
    enable: 'Enable',
    delete: 'Delete',
    add: 'Add',
    addSuccess: 'Add Succeeded',
    saveSuccess: 'Save Succeeded',
    actionSuccess: 'Action Succeeded',
    submitSuccess: 'Submit Succeeded',
    resetPwd: 'Confirm reset password?',
  },
  log: {
    operator: 'Operator',
    target: 'Target',
    type: 'Type',
    content: 'Content',
    time: 'Time',
  },
  bulletin: 'Announcement',
  bulletinDetail: 'Announcement Detail',
};
