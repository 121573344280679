import Vue from 'vue';
import moment from 'moment';
import { i18n } from '@/i18n';
import { JsonViewMaker } from 'vue-json-view-maker';

const state = Vue.observable({
  lang: localStorage.getItem('currentLang') || 'zh',
});

export function getCurrentLang() {
  if (state) {
    return state.lang;
  }
  return localStorage.getItem('currentLang') || 'zh';
}

export function setCurrentLang(lang: string) {
  state.lang = lang;
  i18n.locale = lang;
  JsonViewMaker.setLang(lang);
  moment.locale(lang === 'zh' ? 'zh-cn' : undefined);
  localStorage.setItem('currentLang', lang);
}

export function setToken(token: string, remember?: boolean) {
  let time = 2;
  if (remember) {
    time = 24 * 7;
  }
  localStorage.setItem(
    'token',
    JSON.stringify({
      token,
      time: new Date().valueOf() + time * 60 * 60 * 1000,
    })
  );
}

export function getToken(): undefined | string {
  const json = localStorage.getItem('token');
  if (json) {
    const token = JSON.parse(json);
    if (token.time > new Date().valueOf()) {
      return token.token;
    }
  }
  return undefined;
}

export function removeToken() {
  localStorage.removeItem('token');
}

export function setUser(user: any) {
  localStorage.setItem('user', JSON.stringify(user));
}

export function getUser() {
  const userStr = localStorage.getItem('user') || '';
  return JSON.parse(userStr);
}

export function setTenant(tenant: Object) {
  localStorage.setItem('tenant', JSON.stringify(tenant));
}

export function getTenant() {
  return JSON.parse(localStorage.getItem('tenant') || '{}');
}

/**
 * Remove loading animate
 * @param id parent element id or class
 * @param timeout
 */
export function removeLoadingAnimate(id = '', timeout = 1500) {
  if (id === '') {
    return;
  }
  setTimeout(() => {
    const load = document.getElementById(id);
    if (load) {
      document.body.removeChild(load);
    }
  }, timeout);
}

export function getEnv(): any {
  return process.env.VUE_APP_ENV === 'production'
    ? process.env.VUE_APP_ENV
    : 'default';
}
