































































































import { Component, Vue } from "vue-property-decorator";
import { getCurrentLang, setCurrentLang, getEnv, getTenant } from "@/utils";
import { Tenant } from "@/models/userModels";
import tenantList from "@/tenant.config";

@Component
export default class Login extends Vue {
  public tenantList: any = tenantList;
  public tenant: Tenant = getTenant();
  public loginType: string = this.tenant.loginType[0]; // LDAP INTOA
  public domainList = this.tenant.config[getEnv() as "production" | "default"];
  public name: string = "";
  public domain: string = this.domainList[0];
  public usernameIsError: boolean = false;
  public passwordIsError: boolean = false;
  public lang = getCurrentLang();
  public form = {
    user: "",
    password: "",
  };

  public get rules() {
    return {
      user: [
        {
          required: true,
          message: this.$t("login.emailError"),
          trigger: "blur",
          type: this.isDomainLogin ? undefined : "email",
        },
      ],
      password: [
        {
          required: true,
          message: this.$t("login.passwordError"),
          trigger: "blur",
        },
      ],
    };
  }

  public get userName(): string {
    return (
      (this.form ? this.form.user : "") +
      (this.isDomainLogin ? this.domain : "")
    );
  }

  public get isDomainLogin(): boolean {
    return this.loginType === "LDAP";
  }

  public mounted(): void {
    const { error, loginType } = this.$route.query;
    switch (error) {
      case "usernameOrPassError":
        this.$message.error(this.$t("login.loginError") as string);
        break;
      case "notAssociateApp":
        this.$message.error(this.$t("login.notAssociateApp") as string);
        break;
      case "disable":
        this.$message.error(this.$t("login.disable") as string);
        break;
      case "notSyncLdap":
        this.$message.error(this.$t("login.notSyncLdap") as string);
        break;
      default:
        break;
    }
    if (error) {
      this.$router.push({ name: "login", query: {} });
    }
    if (loginType) {
      this.loginType = loginType as string;
    }
  }

  public setLang(lang: "zh" | "en") {
    this.lang = lang;
    setCurrentLang(lang);
  }

  public changeLoginMode() {
    this.loginType = this.isDomainLogin ? "INTOA" : "LDAP";
  }

  public handleDomainLoginSubmit(e: any) {
    (this.$refs.user as any).onFieldBlur();
    (this.$refs.password as any).onFieldBlur();
    if (!this.form.user || !this.form.password) {
      e.preventDefault();
    }
    return true;
  }
}
