








import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CardLayout extends Vue {
  @Prop()
  public title!: string;
  @Prop({
    default: true,
  })
  public editable!: boolean;
}
