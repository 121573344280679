import axios from '@/utils/fetch';
import { BaseApi } from '@/apis/baseApi';

export class OtherApi extends BaseApi {
  public static qiniuToken() {
    return axios.get('/api/qiniuyun/token').then((res) => {
      return res.data;
    });
  }

  public static async organize(): Promise<any[] | undefined> {
    if (this.cache.organize) {
      return this.cache.organize;
    }
    const rep = await axios.get('/api/organize/all');
    if (rep) {
      this.cache.organize = rep.data;
      return rep.data;
    }
  }

  public static getMenuList(client: string) {
    return axios
      .get('/api/user/current/menu', { params: { client } })
      .then((res) => res.data);
  }

  public static getFormOpt() {
    return axios.get('/api/user/current/form/opt').then((res) => res.data);
  }
}
