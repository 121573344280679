import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { message } from 'ant-design-vue';
import { getCurrentLang, getTenant } from '@/utils';
import router from '@/router';
import qs from 'qs';

const Axios = axios.create({
  timeout: 30000,
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
  },
});

let pending: string[] = []; // 定义(防重复提交)
const CancelToken = axios.CancelToken;
const removePending = (config: AxiosRequestConfig, cancelToken: any) => {
  const url: string = config.url + '&' + config.method;
  if (pending.indexOf(url) >= 0 && url.indexOf('/api/hr/sync&post') === -1) {
    if (cancelToken) {
      cancelToken(url + '：request execution....'); // 执行取消操作
    } else {
      // 从数组中移除
      setTimeout(() => {
        pending.splice(pending.indexOf(url), 1);
      }, 100);
    }
  } else {
    if (cancelToken) {
      pending.push(url);
    }
  }
};

// 请求前拦截器
Axios.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    config.paramsSerializer = (params) =>
      qs.stringify(params, { allowDots: true, arrayFormat: 'repeat' });
    if (config.method === 'post') {
      config.cancelToken = new CancelToken((c) => {
        removePending(config, c);
      });
    }
    const locale = getCurrentLang();
    config.headers['X-Locale'] = locale;
    config.headers['X-Tenant'] = getTenant().tenantId;
    return config;
  },
  (error: any) => Promise.reject(error)
);

const redirectLogin = () => {
  router.push({ name: 'login' }).catch(() => {});
};

// 请求后拦截器
Axios.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: any) => {
    pending = [];
    if (!error.response) {
      message.error('Network Error 网络错误');
      return Promise.reject(error);
    }
    if (error.response.status === 400) {
      const result = error.response.data;
      // 自定义异常
      if (result.status !== 200) {
        if (result.status === 401) {
          redirectLogin();
          return Promise.reject(error);
        } else if (result.status === 1011) {
          message.error(result.error);
          redirectLogin();
          return Promise.reject(error);
        } else {
          message.error(result.error);
          return Promise.reject(error);
        }
      }
    } else if (error.response.status === 401) {
      // message.error('401: Unauthorized 未授权');
      redirectLogin();
      return Promise.reject(error);
    } else if (error.response.status === 404) {
      message.error('404: Not Found 地址不存在');
      return Promise.reject(error);
    } else if (error.response.status === 503) {
      message.error('503: Service Unavailable 服务不可用或请求过快');
      return Promise.reject(error);
    } else {
      message.error('500: Server Exception 服务器异常');
      return Promise.reject(error);
    }
    // reject
    return Promise.reject(error).catch(() => {});
  }
);

// 每5s清空pending
setInterval(() => {
  pending = [];
}, 5000);

declare module 'axios' {
  export interface AxiosInstance {
    delete<T = any>(url: string, config?: AxiosRequestConfig): AxiosPromise<T>;
    head<T = any>(url: string, config?: AxiosRequestConfig): AxiosPromise<T>;
  }
}

export default Axios;
