import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { JsonViewMaker } from 'vue-json-view-maker';
import { getCurrentLang } from '@/utils';
import { zh } from '@/i18n/zh';
import { en } from '@/i18n/en';
import moment from 'moment';
import 'moment/locale/zh-cn';

Vue.use(VueI18n);

const lang = getCurrentLang();
JsonViewMaker.setLang(lang);

moment.locale(lang === 'zh' ? 'zh-cn' : undefined);

export const i18n = new VueI18n({
  locale: lang,
  messages: {
    zh,
    en,
  },
});
